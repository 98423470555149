<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>Editar mensagem</b-card-title>
      </b-card-header>
       <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Titulo -->
          <validation-provider
            #default="validationContext"
            name="Titulo"
            rules="required"
          >
            <b-form-group
              label="Titulo"
              label-for="titulo"
            >
              <b-form-input
                id="titulo"
                v-model="mensagemData.titulo"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Titulo da mensagem"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Assunto Email -->
          <validation-provider
            #default="validationContext"
            name="Assunto Email"
            rules="required"
          >
            <b-form-group
              label="Assunto do Email"
              label-for="assunto_email"
            >
              <b-form-input
                id="assunto_email"
                v-model="mensagemData.assunto_email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Layout de Email -->
          <label>Layout de Email</label>
          <b-row class="mb-2">            
            <b-col cols="2" class="mr-2 mt-1" style="display:flex; flex-direction:column;" v-for="layout in layoutsEmail" :key="layout.id" >
              <b-badge pill :variant="mensagemData.email_layouts_id == layout.id ? 'success' : 'light-secondary'" style="cursor:pointer"
              @click="mensagemData.email_layouts_id == layout.id ? mensagemData.email_layouts_id = null : mensagemData.email_layouts_id = layout.id">
                {{ layout.nome }}
              </b-badge>
              <small style="color:#007bff; cursor: pointer;" @click="showContentLayout(layout.conteudo_html)">Visualizar</small>
            </b-col>            
          </b-row>

          <b-modal
            ok-only
            ok-title="Fechar"
            centered
            size="lg"
            title="Layout email"
            v-model="modalShow"
          >
            <div v-html="contentHtml"></div>
          </b-modal>

          <!-- Conteúdo Padrão -->
          <validation-provider
            #default="validationContext"
            name="Conteudo SMS"
            rules="required"
          >
            <b-form-group
              label="Conteúdo SMS (Limite de 160 caracteres)"
              label-for="conteudo_reduzido"
            >
              <b-form-input
                id="conteudo_reduzido"
                v-model="mensagemData.conteudo_reduzido"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <div>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('small', '%nome%')" size="sm">+Primeiro Nome</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('small', '%nome_completo%')" size="sm">+Nome Completo</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('small', '%titulo%')" size="sm">+Num. Título</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('small', '%venc%')" size="sm">+Vencimento</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('small', '%valor%')" size="sm">+Valor</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('small', '%credor_nome%')" size="sm">+Nome Credor</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('small', '%credor_cod%')" size="sm">+Código Credor</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('small', '%credor_tel%')" size="sm">+Telefone Credor</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('small', '%credor_email%')" size="sm">+Email Credor</b-button>
              </div>

            </b-form-group> 
          </validation-provider>

          <!-- Conteúdo Reduzido -->
          <validation-provider
            #default="validationContext"
            name="Conteudo Whats"
            rules="required"
          >
            <b-form-group
              label="Conteúdo WhatsApp"
              label-for="conteudo_padrao"
            >
              <b-form-input
                id="conteudo_padrao"
                v-model="mensagemData.conteudo_padrao"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <div>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('default', '%nome%')" size="sm">+Primeiro Nome</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('default', '%nome_completo%')" size="sm">+Nome Completo</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('default', '%titulo%')" size="sm">+Num. Título</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('default', '%venc%')" size="sm">+Vencimento</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('default', '%valor%')" size="sm">+Valor</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('default', '%credor_nome%')" size="sm">+Nome Credor</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('default', '%credor_cod%')" size="sm">+Código Credor</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('default', '%credor_tel%')" size="sm">+Telefone Credor</b-button>
                <b-button variant="dark" class="mr-1 mt-1" @click="addField('default', '%credor_email%')" size="sm">+Email Credor</b-button>
              </div>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>     
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BCardHeader, BCardTitle,BBadge,BRow,BCol,BModal
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref,onBeforeMount } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import axios from '@axios'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCard,
    BCardHeader, 
    BCardTitle,
    BBadge,
    BRow,
    BCol,
    BModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required
    }
  },
  setup(props, { emit }) {
    const blankMensagemData = {
      titulo: null,
      assunto_email: null,            
      conteudo_padrao: null,
      conteudo_reduzido: null,
      email_layouts_id: null,
    }

    const layoutsEmail = ref([])
    const contentHtml = ref(null)
    const modalShow = ref(false)

    const mensagemData = ref(JSON.parse(JSON.stringify(blankMensagemData)))

    const resetMensagemData = () => {
      mensagemData.value = JSON.parse(JSON.stringify(blankMensagemData))
    }

    onBeforeMount(() => {
      axios
      .get('mensagens/'+router.currentRoute.params.id)
      .then(response => {
        const { data } = response.data  
        mensagemData.value = data
      })
      .catch(error => reject(error))  

      axios
      .get('email-layouts/')
      .then(response => {
        const { data } = response.data  
        layoutsEmail.value = data
      })
      .catch(error => reject(error))  
    })

    function showContentLayout(html){
      modalShow.value = true
      contentHtml.value = html
    }

    function addField(type, arg){
      if(type == 'small')
        if(mensagemData.value.conteudo_reduzido)
          mensagemData.value.conteudo_reduzido += ' '+arg
        else
          mensagemData.value.conteudo_reduzido = arg
      else
        if(mensagemData.value.conteudo_padrao)
          mensagemData.value.conteudo_padrao += ' '+arg
        else
          mensagemData.value.conteudo_padrao = arg
    }

    function onSubmit() {
     axios
      .put('mensagens/'+router.currentRoute.params.id,{
        ...mensagemData.value
      })
      .then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Alterar mensagem',
            icon: 'CheckIcon',
            variant: 'success',
            text: `Alterado com sucesso!`,
          },
        })
        router.go(-1)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Alterar mensagem',
            icon: 'XIcon',
            variant: 'danger',
            text: `Algo deu errado ):`,
          },
        })
      })  
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetMensagemData)

    return {
      mensagemData,
      layoutsEmail,
      modalShow,
      contentHtml,
      showContentLayout,
      onSubmit,
      addField,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
